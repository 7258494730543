import clsx from 'clsx';
import React from 'react';

import Accordion from '../../atoms/Accordion/Accordion';
import AccordionItemV3 from '../../atoms/Accordion/AccordionItemV3';
import { EAccordionStyle } from '../../atoms/Accordion/constants';
import { Container } from '../../sections/Container';

export interface IAccordionsGridProps {
  theme?: EAccordionStyle;
  isFirstCardOpened?: boolean;
  accordions: (React.ComponentProps<
    typeof Accordion | typeof AccordionItemV3
  > & { id?: string })[];
}

const AccordionsGrid: React.FC<IAccordionsGridProps> = props => {
  const { theme, accordions, isFirstCardOpened = true } = props;
  const AccordionItem =
    theme === EAccordionStyle.AccordionVersion1 ? Accordion : AccordionItemV3;

  return (
    <Container>
      <div
        className={clsx('grid 2xl:grid-cols-12', {
          'gap-4': theme === EAccordionStyle.AccordionVersion1,
        })}
      >
        {accordions.map(({ id, ...rest }, index) => (
          <div key={id ?? index} className='2xl:col-span-8 2xl:col-start-3'>
            <AccordionItem
              isOpened={isFirstCardOpened && index === 0}
              {...rest}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default AccordionsGrid;
