import { AccordionsGrid, RichText } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { EAccordionStyle } from 'front/shared/ds/src/components/atoms/Accordion/constants';
import { useRouter } from 'next/router';
import React from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TAccordionsGridConnectedProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'faq' }
>;

const AccordionsGridConnected: React.FC<
  TAccordionsGridConnectedProps
> = props => {
  const {
    items,
    theme = EAccordionStyle.AccordionVersion1,
    isFirstCardOpened,
  } = props;
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');
  return (
    <>
      {items && (
        <AccordionsGrid
          isFirstCardOpened={isFirstCardOpened}
          theme={theme as EAccordionStyle}
          accordions={items.map(i => ({
            ...i,
            buttons: toCardButtons(i?.buttons, currentLocale, appDispatch),
            _description: <RichText content={i._description} />,
          }))}
        />
      )}
    </>
  );
};

export default AccordionsGridConnected;
