import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconClose } from '../../../../public/icons/icon-close-thin.svg';
import { IconChevronDown } from '../../icons';
import { Button } from '../Button';

export type TAccordionProps = {
  title: string;
  description?: string;
  _description?: React.JSX.Element | null;
  isOpened?: boolean;
  showCloseIcon?: boolean;
  className?: string;
  isActiveClosable?: boolean;
  onClick?: () => void;
  buttons?: React.ComponentProps<typeof Button>[];
};

const Accordion: React.FC<TAccordionProps> = props => {
  const {
    description,
    title,
    _description,
    isOpened,
    className,
    showCloseIcon = true,
    isActiveClosable = true,
    onClick,
  } = props;

  const [animationParent] = useAutoAnimate();
  const [isCollapsed, setIsCollapsed] = useState(!isOpened);

  const sectionClass =
    'cursor-pointer rounded-3xl bg-control-100 px-8 py-6 transition hover:bg-control-150';
  const headerTextClass =
    'text-2xl font-semibold leading-relaxed text-interface-1000 flex justify-between';

  useEffect(() => {
    setIsCollapsed(!isOpened);
  }, [isOpened]);

  const onClickSection = (): void => {
    if (onClick) {
      onClick();
    } else {
      setIsCollapsed(c => !c);
    }
  };

  return (
    <section
      ref={animationParent}
      className={clsx(sectionClass, className, {
        'pointer-events-none': !isCollapsed && !isActiveClosable,
      })}
      onClick={onClickSection}
    >
      <div className='flex items-start justify-between gap-4'>
        <div>
          <h3 className={headerTextClass}>{title}</h3>

          {!isCollapsed && !_description && description && (
            <p className='mt-4 text-base leading-extra-loose text-interface-800'>
              {description}
            </p>
          )}
          {!isCollapsed && _description && (
            <div
              className={clsx(
                'text-base text-interface-800',
                'prose-ul:grid prose-ul:list-disc prose-ul:gap-2 prose-ul:pl-6',
                'prose-ol:grid prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-6',
                'mt-4 leading-extra-loose',
              )}
            >
              {_description}
            </div>
          )}
        </div>
        {showCloseIcon && (_description || description) && (
          <div className='flex h-7 w-4 items-center'>
            {isCollapsed ? (
              <IconChevronDown
                width={16}
                height={16}
                className='aspect-square text-control-600'
              />
            ) : (
              isActiveClosable && (
                <IconClose className='aspect-square size-4 text-control-600' />
              )
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Accordion;
